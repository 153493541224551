<template>
  <div>
    <reference-values />
  </div>
</template>

<script>
import ReferenceValues from '@/components/catalog/ReferenceValues/ReferenceValues.vue'

export default {
  components: { ReferenceValues },
  name: 'ReferenceValue',
}
</script>
